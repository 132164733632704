import { HttpClient, json } from 'aurelia-fetch-client';
import { FetchAuthorizationInterceptor, FetchNotOkInterceptor } from './fetch-interceptors';
import { isGuid, isNullOrUndefined } from '@dts/scriptlib';
import { IAuthToken } from 'services/state/state';

// export interface ValidationController {
//     validate();
// }

export interface ValidateResult {
    valid: boolean;
    message: string | null;
}

export interface IValidator {
    validateObject(object: any, rules?: any): Promise<ValidateResult[]>;
}

export interface IRepository {
    http: HttpClient;
    methods: Methods;
    ping(): Promise<IApiResult>;
    entity(entity: string, guid: string, map?: (src) => IEntity): Promise<IApiResult>;
    fulltext(entity: string, parameters: {}, map?: (src) => IEntity): Promise<IApiResult>;
    merge(entity: string, parameters: {}, map?: (src) => IEntity): Promise<IApiResult>;
    exec(procedure: string, parameters: {}, map?: (src) => IEntity): Promise<IApiResult>;
    adhoc(adhoc: string, parameters: {}, map?: (src) => IEntity): Promise<IApiResult>;
    csv(filename: string, procedure: string, parameters: {}): Promise<void>;
}

export interface IEntity {
    guid: string;
    original: Partial<IEntity>;
    map(src: any): IEntity;

    isNew(): boolean;
    isDirty(): boolean;
    dirtyFields(): string[];

    get(repository: IRepository, guid: string): Promise<IEntity>;
    fulltext(repository: IRepository, filter: string, page?: number, size?: number);
    merge(repository: IRepository): Promise<boolean>;
    canMerge(validator: IValidator): Promise<boolean>;

    refresh(repository: IRepository): void;
    cancel(): void;

    isValid(validator: IValidator): Promise<boolean>;
    invalidFields(validator: IValidator);
}

export interface IApiResult {
    results: any[];
    ok: boolean;
    count: number;
    errors: IApiError[];
    message: string;
    parameters: any;
}

export function emptyResult(): IApiResult {
    return {
        ok: false,
        count: 0,
        results: [],
        errors: [],
        message: '',
        parameters: undefined
    } as IApiResult;
}

export interface IApiError {
    path: string;
    code: string;
    description: string;
}

interface IMethods {
    get(input: Request | string, map?: (src) => IEntity): Promise<IApiResult>;
    post(input: Request | string, body, map?: (src) => IEntity): Promise<IApiResult>;
    put(input: Request | string, body, map?: (src) => IEntity): Promise<IApiResult>;
    fetch(input: Request | string, method, body, map?: (src) => IEntity): Promise<IApiResult>;
}

export class Entity<T> implements IEntity {

    guid: string = null;
    userGuid?: string = null;
    original: Partial<T> = {};

    entityName: string;

    constructor(entityName: string) {
        this.entityName = entityName;
    }

    map(src: any): IEntity {
        throw new Error('Assign method not implemented.');
    }

    isNew() {
        return this.guid === null;
    }

    isDirty(): boolean {
        return this.dirtyFields().length > 0;
    }

    dirtyFields() {
        const dirty = [];
        for (const key of Object.keys(this)) {
            if (this.hasOwnProperty(key) && this.original.hasOwnProperty(key)) {
                if (key.startsWith('_') || key === 'original') {
                    continue;
                    // tslint:disable-next-line: triple-equals
                } else if (this[key] != this.original[key]) {

                    if (isDate(this[key])) {
                        if (!isEqualDates(this[key], this.original[key])) {
                            dirty.push(key);
                        }
                    } else {
                        dirty.push(key);
                    }
                }
            }
        }
        return dirty;
    }

    refresh(repository: IRepository): Promise<IEntity> {
        if (repository && this.guid) {
            return this.get(repository, this.guid);
        }
        return undefined;
    }

    fulltext(repository: IRepository, filter: string, page?: number, size?: number): Promise<IApiResult> {
        if (repository) {
            return repository.fulltext(this.entityName, { filter, page, size }, this.map);
        }
        return undefined;
    }

    cancel() {
        Object.assign(this, this.map(this.original));
    }

    async get(repository: IRepository, guid: string): Promise<IEntity> {
        if (repository && isGuid(guid)) {
            const response = await repository.entity(this.entityName, guid, this.map);
            if (this.processResponse(response)) {
                return this;
            }
        }
        return undefined;
    }

    async merge(repository: IRepository): Promise<boolean> {
        if (repository) {
            const state: IAuthToken = JSON.parse(localStorage.getItem('authToken'));
            this.userGuid = state.currentUser.user;
            const response = await repository.merge(this.entityName, this, this.map);
            if (this.processResponse(response)) {
                return true;
            }
        }
        return false;
    }

    async canMerge(validator: IValidator): Promise<boolean> {
        if (this.isDirty() || this.isNew()) {
            return this.isValid(validator);
        }
        return false;
    }

    async isValid(validator: IValidator): Promise<boolean> {
        if (validator) {
            const results = await validator.validateObject(this);
            return results.every(result => result.valid);
        }
        return true;
    }

    async invalidFields(validator: IValidator) {
        if (validator) {
            const results = await validator.validateObject(this);
            return results.filter(result => {
                if (!result.valid) {
                    return result;
                }
            });
        }
        return [];
    }

    toJSON() {
        const obj = Object.assign({}, this);
        delete obj.original;
        delete obj.entityName;

        for (const key of Object.keys(obj)) {
            if (obj.hasOwnProperty(key) && key.startsWith('_')) {
                delete obj[key];
            }
        }

        return obj;
    }

    private processResponse(response: IApiResult): boolean {
        if (response && response.ok) {
            Object.assign(this, response.results[0]);
            return true;
        }
        return false;
    }
}

// tslint:disable-next-line:max-classes-per-file
export class Repository implements IRepository {

    http: HttpClient;
    methods: Methods;

    execSignals = {};
    fulltextSignals = {};

    constructor(http: HttpClient) {
        this.http = http;
        this.methods = new Methods(http);
    }

    setBaseUrl(url: string) {
        this.http.baseUrl = url;
    }

    ping(): Promise<IApiResult> {
        return this.methods.get(`/ping`);
    }

    entity(entity: string, guid: string, map?: (src) => IEntity): Promise<IApiResult> {
        return this.methods.get(`/${entity}/${guid}`, map);
    }

    fulltext(entity: string, parameters: {}, map?: (src) => IEntity): Promise<IApiResult> {

        // check and cancel previous controller
        if (entity in this.fulltextSignals) {
            const controller = this.fulltextSignals[entity] as AbortController;
            if (!controller.signal.aborted) {
                controller.abort();
            }
            delete this.fulltextSignals[entity];
        }
        this.fulltextSignals[entity] = new AbortController();


        return this.methods.post(`/fulltext/${entity}`, parameters || {}, map, this.fulltextSignals[entity].signal);
    }

    merge(entity: string, parameters: {}, map?: (src) => IEntity): Promise<IApiResult> {
        return this.methods.put(`/${entity}`, parameters || {}, map);
    }

    exec(procedure: string, parameters: {}, map?: (src) => IEntity): Promise<IApiResult> {

        // check and cancel previous controller
        if (procedure in this.execSignals) {
            const controller = this.execSignals[procedure] as AbortController;
            if (!controller.signal.aborted) {
                controller.abort();
            }
        }
        this.execSignals[procedure] = new AbortController();

        return this.methods.post(`/exec/${procedure}`, parameters || {}, map, this.execSignals[procedure].signal);
    }

    adhoc(adhoc: string, parameters: {}, map?: (src) => IEntity): Promise<IApiResult> {
        return this.methods.post(`/adhoc/${adhoc}`, parameters || {}, map);
    }

    async csv(filename: string, procedure: string, parameters: {}): Promise<void> {
        parameters = parameters || {};

        const blobHttpClient: HttpClient = new HttpClient();

        blobHttpClient.configure((config) => {
            config
                .withBaseUrl(this.http.baseUrl)
                .withInterceptor(new FetchAuthorizationInterceptor())
                .withInterceptor(new FetchNotOkInterceptor())
                .useStandardConfiguration();
        });

        const qry = `/csv/${procedure}`;
        const response = await blobHttpClient.fetch(qry, {
            body: json(parameters),
            method: 'post',
        });

        const blob = await response.blob();

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    }
}

// tslint:disable-next-line: max-classes-per-file
class Methods implements IMethods {

    constructor(private readonly http: HttpClient) {
    }

    get(input: Request | string, map?: (src) => IEntity): Promise<IApiResult> {
        return this.fetch(input, 'get', null, map);
    }

    post(input: Request | string, body, map?: (src) => IEntity, signal?: AbortSignal): Promise<IApiResult> {
        return this.fetch(input, 'post', body, map, signal);
    }

    put(input: Request | string, body, map?: (src) => IEntity): Promise<IApiResult> {
        return this.fetch(input, 'put', body, map);
    }

    async fetch(input: Request | string, method, body, map?: (src) => IEntity, signal?: AbortSignal): Promise<IApiResult> {

        const response: Response = await this.fetchInternal(input, method, body, map, signal);

        let apiResult: IApiResult = emptyResult();
        if (response.status === 200) {
            apiResult = await response.json();
        } else if (response instanceof DOMException) {
            const domExc = response as DOMException;
            if (domExc.code === domExc.ABORT_ERR) {
                // AbortSignal called
            }
        } else {
            // console.log(response);
            // if (response) {
            const clone = response.clone();
            const responseError = await clone.text();

            let apiError: IApiResult;
            if (responseError.length > 0) {
                apiError = JSON.parse(responseError);
            } else {

                apiError = {
                    ok: false,
                    results: [],
                    count: 0,
                    errors: [{
                        path: clone.url,
                        code: `${clone.status}`,
                        description: clone.statusText.length > 0 ? clone.statusText : `${clone.status}`
                    }],
                    message: '',
                    parameters: null
                };
            }

            if (apiError.errors) {
                apiResult = { ...apiError };
                apiResult[`parameters`] = body;
            } else {
                apiResult = {
                    results: [],
                    ok: false,
                    count: 0,
                    errors: [{
                        path: response.url,
                        code: response.status.toString(),
                        description: response.statusText
                    }],
                    parameters: body,
                    message: response.statusText
                };
            }
        }

        if (apiResult && map && apiResult.count > 0) {
            apiResult.results = apiResult.results.map(map);
        }

        return apiResult;
    }

    private async fetchInternal(input: Request | string, method, body, map?: (src) => IEntity, signal?: AbortSignal): Promise<Response> {
        let response: Response;
        const url = `${this.http.baseUrl}${input}`;
        try {
            if (body) {
                body = json(body);
                response = await this.http.fetch(url, {
                    body,
                    method,
                    signal
                });
            } else {
                response = await this.http.fetch(url, {
                    method,
                    signal
                });
            }
        } catch (exception) {
            return exception;
        }
        return response;
    }
}

function toApiError(e, url?: string): IApiResult {
    const apiResult: IApiResult = emptyResult();

    if (e instanceof DOMException) {
        apiResult.errors.push({
            path: url || '',
            code: `${e.code}`,
            description: e.message
        } as IApiError);
    }

    if (e instanceof TypeError) {
        apiResult.message = `${e.message}, please check your internet connection`;
        apiResult.errors.push({
            path: url || '',
            description: apiResult.message,
        } as IApiError);
    }

    return apiResult;
}

function abortableFetch(input: RequestInfo, init?: RequestInit): { abort: (timeout?: number) => void; ready: Promise<Response>; } {
    const controller = new AbortController();
    const signal = controller.signal;

    return {
        abort: (timeout?: number) => {
            if (timeout && timeout > 0) {
                setTimeout(() => controller.abort(), timeout);
            } else {
                controller.abort();
            }
        },
        ready: fetch(input, { ...init, signal })
    };
}

function isDate(input) {
    if (Object.prototype.toString.call(input) === "[object Date]")
        return true;
    return false;
};

function isDateString(s) {
    if (isNaN(s) && !isNaN(Date.parse(s)))
        return true;
    else return false;
}

function isEqualDates(date1, date2) {
    if (isNullOrUndefined(date1) || isNullOrUndefined(date2)) {
        return false;
    } else {
        // console.log('isEqualDates', date1, date2);
        // const date1string = isDate(date1) ? toISOString(date1) : date1.toString();
        // const date2string = isDate(date2) ? toISOString(date2) : date2.toString();

        const d1 = isDate(date1) ? date1 : isDateString(date1) ? new Date(date1) : undefined;
        const d2 = isDate(date2) ? date2 : isDateString(date2) ? new Date(date2) : undefined;

        return d1.getTime() === d2.getTime();
    }
}

function toISOString(date) {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
}