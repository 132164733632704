import { FrameworkConfiguration } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import './custom-rules';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    PLATFORM.moduleName('./pagination/result-pager-pagination')
  ]);
}

export * from './fetch-interceptors';
export * from './repository';

export { ImpiloDb } from './models/impilo-db';

export * from './result-pager';
