import { HttpClient, Interceptor } from 'aurelia-fetch-client';
import { autoinject, Container } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { IAuthToken } from 'services/state/state';
import { ToastrService } from 'services/toastr-service';

import { getBrowserId, getFingerprint, getFingerprintAsync, getSession } from '@dts/scriptlib';
import { hubConnectionId } from 'services/hub-service';


export class FetchAuthorizationInterceptor implements Interceptor {

  public ACCESS_TOKEN = 'bearer';

  public toJSON() {
    return { ACCESS_TOKEN: this.ACCESS_TOKEN };
  }

  public async request(request: Request) {
    const state: IAuthToken = JSON.parse(localStorage.getItem('authToken'));
    this.ACCESS_TOKEN = state?.token;

    let fingerprint = getFingerprint();
    if (!fingerprint) {
        fingerprint = await getFingerprintAsync();
    }
    request.headers.append('X-User', state?.currentUser.user);
    request.headers.append('X-Session', `${getSession()}`);
    request.headers.append('X-Browser', `${getBrowserId()}`);
    request.headers.append('X-Fingerprint', `${fingerprint}`);
    request.headers.append('X-App', 'Web');
    request.headers.append('X-Connection-Id', `${hubConnectionId}`)

    if (this.ACCESS_TOKEN) {
      request.headers.append('Authorization', `Bearer ${this.ACCESS_TOKEN}`);
    }
    return request;
  }
}

// tslint:disable-next-line:max-classes-per-file
export class Fetch401Interceptor implements Interceptor {
  public async response?(response: Response, request?: Request) {
    if (response.status === 401 && response.headers.has('Token-Expired')) {
      Container.instance.get(ToastrService).warning('Your login credentials have exired. Please login again.');
      Container.instance.get(Router).navigateToRoute('login');
    }
    /* else if (response.status === 401) {

      const clone = response.clone();

      let errMsg = `(${clone.status}) ${clone.statusText}<br/>`;
      errMsg += `url: ${clone.url}<br/>`;
      Container.instance.get(ToastrService).error(errMsg);
    }
    */

    return response;
  }
}

// tslint:disable-next-line:max-classes-per-file
export class FetchNotOkInterceptor implements Interceptor {

  public requestError?(error: any): Request | Response | Promise<Request | Response> {
    Container.instance.get(ToastrService).error(error);
    throw error;
  }

  public async response?(response: Response, request?: Request) {
    if (!response.ok) {

      const clone = response.clone();
      const responseError = await clone.text();

      let errMsg = clone.statusText;

      if ((clone.status >= 200 && clone.status <= 401) || clone.status === 500) {
        const apiError = JSON.parse(responseError);
        if (apiError.errors) {
          errMsg = '';
          apiError.errors.forEach((err: any) => {
            errMsg += `(${err.code}) ${err.description}<br/>`;
            if (err.path) {
              errMsg += `url: ${err.path}<br/>`;
            }
          });
        }

        console.log(errMsg);

        if (clone.status === 500) {
          Container.instance.get(ToastrService).error(errMsg);
        }
      }
      // alert(errMsg.length > 5 ? errMsg.substring(0, errMsg.length - 5) : errMsg);
    }
    return response;
  }

  public responseError?(error: any, request?: Request, httpClient?: HttpClient): Response | Promise<Response> {
    if (error instanceof DOMException && error.code === DOMException.ABORT_ERR) {
      // AbortSignal called
    } else {

      let errMsg = `Please check your internet connection<br/>`;
      if (request) {
        errMsg += `path: ${request.url}<br/>`;
      }
      errMsg += `text: ${error.message}<br/>`;
      Container.instance.get(ToastrService).error(errMsg.length > 5 ? errMsg.substring(0, errMsg.length - 5) : errMsg);
    }
    throw error;
  }
}

export class FetchDebugInterceptor implements Interceptor {

  public async response?(response: Response, request?: Request) {
    const clone = response.clone();
    const body = await clone.json();
    console.log(JSON.stringify(body));
    return response;
  }
}
