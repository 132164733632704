/**
 * Generated code, do not modify
 */
/* eslint-disable */
import { ValidationRules } from 'aurelia-validation';
import { Entity, IApiResult, IEntity, Repository } from '../repository';

export namespace ImpiloDb {

  export class ImpiloDbRepository extends Repository {

    protected procedures: Procedures = new Procedures(this);

    constructor(http) {
      super(http);
    }
  }

  export enum ProcedureEnum {
        _ft_,
        _get_,
        _merge_,
        spfAspNetUser,
        spfCareHome,
        spfDoctor,
        spfICD10,
        spfMedication,
        spfPackingGroup,
        spfPatient,
        spfPharmacy,
        spfSubGroup,
        spfUserSession,
        spgAspNetRefreshToken,
        spgAspNetUser,
        spgBlister,
        spgCareHome,
        spgDrugInteractionRequest,
        spgEMailInQueue,
        spgExtract,
        spgHistory,
        spgHistoryEntity,
        spgHtmlToPdfInQueue,
        spgImage,
        spgLookup,
        spgMedication,
        spgMedicationCodeIsUnique,
        spgMedicationSchedule,
        spgMedicationSchedulePivot,
        spgNotice,
        spgPackingGroup,
        spgPatient,
        spgPatientHistory,
        spgPatientMeasurement,
        spgPatientMedication,
        spgPatientMedicationAllowed,
        spgPatientMedicationHistory,
        spgPharmacy,
        spgPreload,
        spgPrintWeekLog,
        spgSubGroup,
        spgTreatmentPlanCompiled,
        spgTreatmentPlanQueue,
        spgUserSession,
        spiEMailQueue,
        spiHtmlToPdfQueue,
        spjPatientPack,
        spmFrequency,
        spmHtmlToPdf,
        spmImage,
        spmMedication,
        spmMedicationSchedule,
        spmMedicationScheduleComplex,
        spmPatient,
        spmPatientMedication,
        spmPatientMedicationClone,
        spmPatientPackBarcode,
        spmPatientPhoto,
        spmPharmacy,
        spmPharmacyUser,
        spmPrintWeekLog,
        spmPublicDomainProducts,
        spmTreatmentPlan,
        spmUserSession,
        sprGenerateScript,
        sprLabels,
        sprMedicationScheduled,
        sprMedicationUsage,
        sprPatientList,
        sprPatientPacks,
        sprPlaceOrder,
        sprToBePackedForWeek,
        sprTreatmentPlans,
        spuEMailStatus,
        spuMedicationPackSchedule,
        spuPharmacySetup,
  }

  class Procedures {

    private readonly maps: Map<ProcedureEnum, (src) => IEntity> = new Map();

    constructor(private readonly repository: Repository) {
    }

    setMapping(procedure: ProcedureEnum, map: (src) => IEntity) {
      this.maps.set(procedure, map);
    }

    _ft_(entity: string, filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum._ft_);
      return this.repository.exec('_ft_', {
                entity,
                filter,
                page,
                size,
                total,
      }, map);
    }

    _get_(entity: string, guid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum._get_);
      return this.repository.exec('_get_', {
                entity,
                guid,
      }, map);
    }

    _merge_(entity: string, keyValues: any): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum._merge_);
      return this.repository.exec('_merge_', {
                entity,
                keyValues,
      }, map);
    }

    spfAspNetUser(filter: string, pharmacyGuid: string = null, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spfAspNetUser);
      return this.repository.exec('spfAspNetUser', {
                filter,
                pharmacyGuid,
                page,
                size,
                total,
      }, map);
    }

    spfCareHome(filter: string, pharmacyGuid: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spfCareHome);
      return this.repository.exec('spfCareHome', {
                filter,
                pharmacyGuid,
                page,
                size,
                total,
      }, map);
    }

    spfDoctor(filter: string, pharmacyGuid: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spfDoctor);
      return this.repository.exec('spfDoctor', {
                filter,
                pharmacyGuid,
                page,
                size,
                total,
      }, map);
    }

    spfICD10(filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spfICD10);
      return this.repository.exec('spfICD10', {
                filter,
                page,
                size,
                total,
      }, map);
    }

    spfMedication(filter: string, activeOnly: boolean = true, missingImage: boolean = false, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spfMedication);
      return this.repository.exec('spfMedication', {
                filter,
                activeOnly,
                missingImage,
                page,
                size,
                total,
      }, map);
    }

    spfPackingGroup(filter: string, pharmacyGuid: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spfPackingGroup);
      return this.repository.exec('spfPackingGroup', {
                filter,
                pharmacyGuid,
                page,
                size,
                total,
      }, map);
    }

    spfPatient(filter: string, pharmacyGuid: string, careHomeGuid: string = null, subGroupGuid: string = null, packingGroupGuid: string = null, isActive: boolean = true, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spfPatient);
      return this.repository.exec('spfPatient', {
                filter,
                pharmacyGuid,
                careHomeGuid,
                subGroupGuid,
                packingGroupGuid,
                isActive,
                page,
                size,
                total,
      }, map);
    }

    spfPharmacy(filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spfPharmacy);
      return this.repository.exec('spfPharmacy', {
                filter,
                page,
                size,
                total,
      }, map);
    }

    spfSubGroup(filter: string, pharmacyGuid: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spfSubGroup);
      return this.repository.exec('spfSubGroup', {
                filter,
                pharmacyGuid,
                page,
                size,
                total,
      }, map);
    }

    spfUserSession(filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spfUserSession);
      return this.repository.exec('spfUserSession', {
                filter,
                page,
                size,
                total,
      }, map);
    }

    spgAspNetRefreshToken(guid: string = null, userGuid: string = null, revoke: boolean = false, page: number = 1, size: number = 10): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgAspNetRefreshToken);
      return this.repository.exec('spgAspNetRefreshToken', {
                guid,
                userGuid,
                revoke,
                page,
                size,
      }, map);
    }

    spgAspNetUser(guid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgAspNetUser);
      return this.repository.exec('spgAspNetUser', {
                guid,
      }, map);
    }

    spgBlister(guid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgBlister);
      return this.repository.exec('spgBlister', {
                guid,
      }, map);
    }

    spgCareHome(guid: string = null, pharmacyGuid: string = null, userGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgCareHome);
      return this.repository.exec('spgCareHome', {
                guid,
                pharmacyGuid,
                userGuid,
      }, map);
    }

    spgDrugInteractionRequest(patientGuid: string = null, barcode: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgDrugInteractionRequest);
      return this.repository.exec('spgDrugInteractionRequest', {
                patientGuid,
                barcode,
      }, map);
    }

    spgEMailInQueue(top: number = 1): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgEMailInQueue);
      return this.repository.exec('spgEMailInQueue', {
                top,
      }, map);
    }

    spgExtract(pharmacyGuid: string, userGuid: string, extract: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgExtract);
      return this.repository.exec('spgExtract', {
                pharmacyGuid,
                userGuid,
                extract,
      }, map);
    }

    spgHistory(entity: string, patientGuid: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgHistory);
      return this.repository.exec('spgHistory', {
                entity,
                patientGuid,
                page,
                size,
                total,
      }, map);
    }

    spgHistoryEntity(entity: string, guid: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgHistoryEntity);
      return this.repository.exec('spgHistoryEntity', {
                entity,
                guid,
                page,
                size,
                total,
      }, map);
    }

    spgHtmlToPdfInQueue(top: number = 1): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgHtmlToPdfInQueue);
      return this.repository.exec('spgHtmlToPdfInQueue', {
                top,
      }, map);
    }

    spgImage(entity: string, guid: string = null, id: number = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgImage);
      return this.repository.exec('spgImage', {
                entity,
                guid,
                id,
      }, map);
    }

    spgLookup(entity: string, pharmacyGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgLookup);
      return this.repository.exec('spgLookup', {
                entity,
                pharmacyGuid,
      }, map);
    }

    spgMedication(guid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgMedication);
      return this.repository.exec('spgMedication', {
                guid,
      }, map);
    }

    spgMedicationCodeIsUnique(code: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgMedicationCodeIsUnique);
      return this.repository.exec('spgMedicationCodeIsUnique', {
                code,
      }, map);
    }

    spgMedicationSchedule(medicationGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgMedicationSchedule);
      return this.repository.exec('spgMedicationSchedule', {
                medicationGuid,
      }, map);
    }

    spgMedicationSchedulePivot(patientMedicationGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgMedicationSchedulePivot);
      return this.repository.exec('spgMedicationSchedulePivot', {
                patientMedicationGuid,
      }, map);
    }

    spgNotice(guid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgNotice);
      return this.repository.exec('spgNotice', {
                guid,
      }, map);
    }

    spgPackingGroup(guid: string = null, pharmacyGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgPackingGroup);
      return this.repository.exec('spgPackingGroup', {
                guid,
                pharmacyGuid,
      }, map);
    }

    spgPatient(guid: string, includeImages: boolean = true): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgPatient);
      return this.repository.exec('spgPatient', {
                guid,
                includeImages,
      }, map);
    }

    spgPatientHistory(patientGuid: string, asOf: Date = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgPatientHistory);
      return this.repository.exec('spgPatientHistory', {
                patientGuid,
                asOf,
      }, map);
    }

    spgPatientMeasurement(guid: string = null, patientGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgPatientMeasurement);
      return this.repository.exec('spgPatientMeasurement', {
                guid,
                patientGuid,
      }, map);
    }

    spgPatientMedication(guid: string = null, patientGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgPatientMedication);
      return this.repository.exec('spgPatientMedication', {
                guid,
                patientGuid,
      }, map);
    }

    spgPatientMedicationAllowed(patientGuid: string, medicationGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgPatientMedicationAllowed);
      return this.repository.exec('spgPatientMedicationAllowed', {
                patientGuid,
                medicationGuid,
      }, map);
    }

    spgPatientMedicationHistory(patientMedicationGuid: string, asOf: Date = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgPatientMedicationHistory);
      return this.repository.exec('spgPatientMedicationHistory', {
                patientMedicationGuid,
                asOf,
      }, map);
    }

    spgPharmacy(guid: string = null, userGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgPharmacy);
      return this.repository.exec('spgPharmacy', {
                guid,
                userGuid,
      }, map);
    }

    spgPreload(entity: string, pharmacyGuid: string, patientGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgPreload);
      return this.repository.exec('spgPreload', {
                entity,
                pharmacyGuid,
                patientGuid,
      }, map);
    }

    spgPrintWeekLog(pharmacyGuid: string, printType: string = null, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgPrintWeekLog);
      return this.repository.exec('spgPrintWeekLog', {
                pharmacyGuid,
                printType,
                page,
                size,
                total,
      }, map);
    }

    spgSubGroup(guid: string = null, careHomeGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgSubGroup);
      return this.repository.exec('spgSubGroup', {
                guid,
                careHomeGuid,
      }, map);
    }

    spgTreatmentPlanCompiled(patientIds: string, weekFlag1: boolean = true, weekFlag2: boolean = true, weekFlag3: boolean = true, weekFlag4: boolean = true): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgTreatmentPlanCompiled);
      return this.repository.exec('spgTreatmentPlanCompiled', {
                patientIds,
                weekFlag1,
                weekFlag2,
                weekFlag3,
                weekFlag4,
      }, map);
    }

    spgTreatmentPlanQueue(editedDiffSeconds: number = 30): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgTreatmentPlanQueue);
      return this.repository.exec('spgTreatmentPlanQueue', {
                editedDiffSeconds,
      }, map);
    }

    spgUserSession(app: string, remoteIpAddress: string, fingerprint: string, browserId: string, userAgent: string, identityId: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spgUserSession);
      return this.repository.exec('spgUserSession', {
                app,
                remoteIpAddress,
                fingerprint,
                browserId,
                userAgent,
                identityId,
      }, map);
    }

    spiEMailQueue(address: string, subject: string, body: string, senderName: string, senderAddress: string, carbonCopy: string, attachment: any, attachmentName: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spiEMailQueue);
      return this.repository.exec('spiEMailQueue', {
                address,
                subject,
                body,
                senderName,
                senderAddress,
                carbonCopy,
                attachment,
                attachmentName,
      }, map);
    }

    spiHtmlToPdfQueue(html: string, htmlDeflated: any, pharmacyGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spiHtmlToPdfQueue);
      return this.repository.exec('spiHtmlToPdfQueue', {
                html,
                htmlDeflated,
                pharmacyGuid,
      }, map);
    }

    spjPatientPack(barcode: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spjPatientPack);
      return this.repository.exec('spjPatientPack', {
                barcode,
      }, map);
    }

    spmFrequency(entity: string, entityGuid: string, occurrence: number, lastUsed: Date, guid: string = null, pharmacyGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmFrequency);
      return this.repository.exec('spmFrequency', {
                guid,
                entity,
                entityGuid,
                occurrence,
                lastUsed,
                pharmacyGuid,
      }, map);
    }

    spmHtmlToPdf(guid: string, pdf: any): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmHtmlToPdf);
      return this.repository.exec('spmHtmlToPdf', {
                guid,
                pdf,
      }, map);
    }

    spmImage(entity: string, guid: string, photo: any = null, photoName: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmImage);
      return this.repository.exec('spmImage', {
                entity,
                guid,
                photo,
                photoName,
      }, map);
    }

    spmMedication(code: string, name: string, description: string, isActive: boolean, isDiscontinued: boolean, guid: string = null, schedule: string = null, strength: string = null, form: string = null, photo: any = null, photoName: string = null, userGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmMedication);
      return this.repository.exec('spmMedication', {
                guid,
                code,
                name,
                description,
                schedule,
                strength,
                form,
                isActive,
                isDiscontinued,
                photo,
                photoName,
                userGuid,
      }, map);
    }

    spmMedicationSchedule(patientMedicationGuid: string, weekdays: string, one: number, two: number, three: number, four: number, five: number, userGuid: string = null, returnResults: boolean = true): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmMedicationSchedule);
      return this.repository.exec('spmMedicationSchedule', {
                patientMedicationGuid,
                weekdays,
                one,
                two,
                three,
                four,
                five,
                userGuid,
                returnResults,
      }, map);
    }

    spmMedicationScheduleComplex(patientMedicationGuid: string, week: number, day: number, one: number, two: number, three: number, four: number, five: number, userGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmMedicationScheduleComplex);
      return this.repository.exec('spmMedicationScheduleComplex', {
                patientMedicationGuid,
                week,
                day,
                one,
                two,
                three,
                four,
                five,
                userGuid,
      }, map);
    }

    spmPatient(firstName: string, surname: string, isActive: boolean, guid: string = null, idPassportNumber: string = null, cellPhoneNumber: string = null, telephoneNumber: string = null, emailAddress: string = null, notes: string = null, addressLine1: string = null, addressLine2: string = null, suburb: string = null, city: string = null, 
postalCode: number = null, photoName: string = null, photo: any = null, pharmacyProfileNumber: string = null, homeReferenceNumber: string = null, contactName: string = null, contactSurname: string = null, careHomeName: string = null, alternate: string = null, medicalAid: string = null, 
medicalAidNumber: string = null, allergies: string = null, medicalConditions: string = null, contactNotes: string = null, lastPrinted: Date = null, medicationOrderEmailAddress: string = null, titleGuid: string = null, genderGuid: string = null, pharmacyGuid: string = null, careHomeGuid: string = null, 
subGroupGuid: string = null, userGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmPatient);
      return this.repository.exec('spmPatient', {
                guid,
                firstName,
                surname,
                idPassportNumber,
                cellPhoneNumber,
                telephoneNumber,
                emailAddress,
                notes,
                addressLine1,
                addressLine2,
                suburb,
                city,
                postalCode,
                photoName,
                photo,
                pharmacyProfileNumber,
                homeReferenceNumber,
                contactName,
                contactSurname,
                careHomeName,
                alternate,
                medicalAid,
                medicalAidNumber,
                allergies,
                medicalConditions,
                isActive,
                contactNotes,
                lastPrinted,
                medicationOrderEmailAddress,
                titleGuid,
                genderGuid,
                pharmacyGuid,
                careHomeGuid,
                subGroupGuid,
                userGuid,
      }, map);
    }

    spmPatientMedication(scriptValidTo: Date, dateWritten: Date, repeats: number, isComprehensiveSchedule: boolean, created: Date, patientGuid: string, medicationGuid: string, doctorGuid: string, iCD10Guid: string, patientMedicationStatusGuid: string, guid: string = null, pharmacyRXNumber: string = null, instructions: string = null, refillFrequency: number = null, refillDate: Date = null, lastEdited: Date = null, userGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmPatientMedication);
      return this.repository.exec('spmPatientMedication', {
                guid,
                scriptValidTo,
                dateWritten,
                pharmacyRXNumber,
                instructions,
                repeats,
                isComprehensiveSchedule,
                refillFrequency,
                refillDate,
                created,
                lastEdited,
                patientGuid,
                medicationGuid,
                doctorGuid,
                iCD10Guid,
                patientMedicationStatusGuid,
                userGuid,
      }, map);
    }

    spmPatientMedicationClone(patientMedicationGuid: string, medicationGuid: string, substitute: boolean, cloneSchedule: boolean, icd10Guid: string = null, statusGuid: string = null, instructions: string = null, userGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmPatientMedicationClone);
      return this.repository.exec('spmPatientMedicationClone', {
                patientMedicationGuid,
                medicationGuid,
                substitute,
                cloneSchedule,
                icd10Guid,
                statusGuid,
                instructions,
                userGuid,
      }, map);
    }

    spmPatientPackBarcode(treatmentPlanGuid: string, printWeekLogGuid: string, weekNo: number, startDate: Date, endDate: Date, userGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmPatientPackBarcode);
      return this.repository.exec('spmPatientPackBarcode', {
                treatmentPlanGuid,
                printWeekLogGuid,
                weekNo,
                startDate,
                endDate,
                userGuid,
      }, map);
    }

    spmPatientPhoto(guid: string, photo: any = null, photoName: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmPatientPhoto);
      return this.repository.exec('spmPatientPhoto', {
                guid,
                photo,
                photoName,
      }, map);
    }

    spmPharmacy(name: string, guid: string = null, bHFNumber: number = null, contactPerson: string = null, telephoneNumber: string = null, cellPhoneNumber: string = null, emailAddress: string = null, addressLine1: string = null, addressLine2: string = null, suburb: string = null, city: string = null, 
postalCode: number = null, logo: any = null, logoName: string = null, isActive: boolean = false, faxNumber: string = null, accountType: number = 0, billedAnnually: boolean = false, vATRegistrationNumber: string = null, isPrintingDisabled: boolean = true, sendWeeklyToBePackedReport: boolean = false, 
aPIUserName: string = null, aPIPassword: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmPharmacy);
      return this.repository.exec('spmPharmacy', {
                guid,
                name,
                bHFNumber,
                contactPerson,
                telephoneNumber,
                cellPhoneNumber,
                emailAddress,
                addressLine1,
                addressLine2,
                suburb,
                city,
                postalCode,
                logo,
                logoName,
                isActive,
                faxNumber,
                accountType,
                billedAnnually,
                vATRegistrationNumber,
                isPrintingDisabled,
                sendWeeklyToBePackedReport,
                aPIUserName,
                aPIPassword,
      }, map);
    }

    spmPharmacyUser(pharmacyGuid: string, userGuid: string, isDefaultPharmacy: boolean, guid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmPharmacyUser);
      return this.repository.exec('spmPharmacyUser', {
                guid,
                pharmacyGuid,
                userGuid,
                isDefaultPharmacy,
      }, map);
    }

    spmPrintWeekLog(printType: string, updateLastPrinted: boolean, pharmacyGuid: string, guid: string = null, patientList: string = null, date: Date = null, week1: boolean = true, week2: boolean = true, week3: boolean = true, week4: boolean = true, storedLocation: string = null, userGuid: string = null): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmPrintWeekLog);
      return this.repository.exec('spmPrintWeekLog', {
                guid,
                printType,
                updateLastPrinted,
                patientList,
                date,
                week1,
                week2,
                week3,
                week4,
                storedLocation,
                pharmacyGuid,
                userGuid,
      }, map);
    }

    spmPublicDomainProducts(): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmPublicDomainProducts);
      return this.repository.exec('spmPublicDomainProducts', {

      }, map);
    }

    spmTreatmentPlan(patientId: number, pdf1: any, pdf2: any, pdf3: any, pdf4: any): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmTreatmentPlan);
      return this.repository.exec('spmTreatmentPlan', {
                patientId,
                pdf1,
                pdf2,
                pdf3,
                pdf4,
      }, map);
    }

    spmUserSession(sessionGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spmUserSession);
      return this.repository.exec('spmUserSession', {
                sessionGuid,
      }, map);
    }

    sprGenerateScript(patientGuid: string, medicationWithQuantity: string, notes: string, userName: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.sprGenerateScript);
      return this.repository.exec('sprGenerateScript', {
                patientGuid,
                medicationWithQuantity,
                notes,
                userName,
      }, map);
    }

    sprLabels(pharmacyGuid: string, patientIds: string, includeImages: number, startWeek: Date = null, weekFlag1: boolean = true, weekFlag2: boolean = true, weekFlag3: boolean = true, weekFlag4: boolean = true): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.sprLabels);
      return this.repository.exec('sprLabels', {
                pharmacyGuid,
                patientIds,
                includeImages,
                startWeek,
                weekFlag1,
                weekFlag2,
                weekFlag3,
                weekFlag4,
      }, map);
    }

    sprMedicationScheduled(pharmacyGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.sprMedicationScheduled);
      return this.repository.exec('sprMedicationScheduled', {
                pharmacyGuid,
      }, map);
    }

    sprMedicationUsage(pharmacyGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.sprMedicationUsage);
      return this.repository.exec('sprMedicationUsage', {
                pharmacyGuid,
      }, map);
    }

    sprPatientList(pharmacyGuid: string, patientIds: string, includeImages: number, startWeek: Date = null, consolidate: boolean = false, weekFlag1: boolean = true, weekFlag2: boolean = true, weekFlag3: boolean = true, weekFlag4: boolean = true): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.sprPatientList);
      return this.repository.exec('sprPatientList', {
                pharmacyGuid,
                patientIds,
                includeImages,
                startWeek,
                consolidate,
                weekFlag1,
                weekFlag2,
                weekFlag3,
                weekFlag4,
      }, map);
    }

    sprPatientPacks(barcode: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.sprPatientPacks);
      return this.repository.exec('sprPatientPacks', {
                barcode,
      }, map);
    }

    sprPlaceOrder(patientGuid: string, medicationWithQuantity: string, notes: string, userName: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.sprPlaceOrder);
      return this.repository.exec('sprPlaceOrder', {
                patientGuid,
                medicationWithQuantity,
                notes,
                userName,
      }, map);
    }

    sprToBePackedForWeek(pharmacyGuid: string, date: Date): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.sprToBePackedForWeek);
      return this.repository.exec('sprToBePackedForWeek', {
                pharmacyGuid,
                date,
      }, map);
    }

    sprTreatmentPlans(pharmacyGuid: string, patientIds: string, includeImages: number, startWeek: Date = null, weekFlag1: boolean = true, weekFlag2: boolean = true, weekFlag3: boolean = true, weekFlag4: boolean = true): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.sprTreatmentPlans);
      return this.repository.exec('sprTreatmentPlans', {
                pharmacyGuid,
                patientIds,
                includeImages,
                startWeek,
                weekFlag1,
                weekFlag2,
                weekFlag3,
                weekFlag4,
      }, map);
    }

    spuEMailStatus(GUID: string, Status: string, StatusMessage: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spuEMailStatus);
      return this.repository.exec('spuEMailStatus', {
                GUID,
                Status,
                StatusMessage,
      }, map);
    }

    spuMedicationPackSchedule(id: number, status: string, statusReason: string, statusAt: number, statusByGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spuMedicationPackSchedule);
      return this.repository.exec('spuMedicationPackSchedule', {
                id,
                status,
                statusReason,
                statusAt,
                statusByGuid,
      }, map);
    }

    spuPharmacySetup(pharmacyGuid: string): Promise<IApiResult> {
      const map = this.maps.get(ProcedureEnum.spuPharmacySetup);
      return this.repository.exec('spuPharmacySetup', {
                pharmacyGuid,
      }, map);
    }

  }
}

export namespace ImpiloDb.Tables {

  export class AspNetRefreshToken extends Entity<AspNetRefreshToken> {

    id: number;
    created: Date = undefined;
    modified: Date = undefined;
    token: string = undefined;
    expires: Date = undefined;
    remoteIpAddress: string = undefined;
    identityId: string = undefined;


    constructor() {
      super('AspNetRefreshToken');
      ValidationRules
        .ensure((e: AspNetRefreshToken) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.modified).required().satisfiesRule('date')
        .ensure((e) => e.token).required().maxLength(512)
        .ensure((e) => e.expires).required().satisfiesRule('date')
        .ensure((e) => e.remoteIpAddress).required().maxLength(20)
        .ensure((e) => e.identityId).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): AspNetRefreshToken {
      const entity = Object.assign(new AspNetRefreshToken(), src) as AspNetRefreshToken;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return AspNetRefreshToken.map(src);
    }
  }

  export class Blister extends Entity<Blister> {

    id: number;
    name?: string = null;
    logo?: any;
    template?: string = null;

    constructor() {
      super('Blister');
      ValidationRules
        .ensure((e: Blister) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.name).maxLength(50)
        .ensure((e) => e.template).maxLength(8388608)
        .on(this);
    }

    static map(src?: any): Blister {
      const entity = Object.assign(new Blister(), src) as Blister;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return Blister.map(src);
    }
  }

  export class BlisterTemplate extends Entity<BlisterTemplate> {

    id: number;
    week: number;
    day: number;
    timeSlot: number;
    blisterGuid: string = undefined;

    private _blister: Blister;
    get blister(): Blister {
      return this._blister;
    }
    set blister(value: Blister) {
      this._blister = value;
      this.blisterGuid = value?.guid ?? null;
    }

    constructor() {
      super('BlisterTemplate');
      ValidationRules
        .ensure((e: BlisterTemplate) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.week).required().satisfiesRule('int')
        .ensure((e) => e.day).required().satisfiesRule('int')
        .ensure((e) => e.timeSlot).required().satisfiesRule('int')
        .ensure((e) => e.blisterGuid).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): BlisterTemplate {
      const entity = Object.assign(new BlisterTemplate(), src) as BlisterTemplate;
      entity.blister = Object.assign(new Blister(), entity.blister);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return BlisterTemplate.map(src);
    }
  }

  export class CareHome extends Entity<CareHome> {

    id: number;
    name: string = undefined;
    addressLine1?: string = null;
    addressLine2?: string = null;
    suburb?: string = null;
    city?: string = null;
    postalCode?: string = null;
    contactPerson?: string = null;
    contactPersonCell?: string = null;
    telephone?: string = null;
    emailAddress?: string = null;
    isActive: boolean = undefined;
    orderBy: number;
    pharmacyGuid: string = undefined;

    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }

    constructor() {
      super('CareHome');
      ValidationRules
        .ensure((e: CareHome) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.name).required().maxLength(255)
        .ensure((e) => e.addressLine1).maxLength(255)
        .ensure((e) => e.addressLine2).maxLength(255)
        .ensure((e) => e.suburb).maxLength(255)
        .ensure((e) => e.city).maxLength(255)
        .ensure((e) => e.postalCode).maxLength(255)
        .ensure((e) => e.contactPerson).maxLength(255)
        .ensure((e) => e.contactPersonCell).maxLength(255)
        .ensure((e) => e.telephone).maxLength(255)
        .ensure((e) => e.emailAddress).maxLength(255)
        .ensure((e) => e.isActive).required().satisfiesRule('bit')
        .ensure((e) => e.orderBy).required().satisfiesRule('int')
        .ensure((e) => e.pharmacyGuid).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): CareHome {
      const entity = Object.assign(new CareHome(), src) as CareHome;
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return CareHome.map(src);
    }
  }

  export class CareHomeUser extends Entity<CareHomeUser> {

    id: number;
    careHomeGuid: string = undefined;
    userGuid: string = undefined;
    isDefaultCareHome: boolean = undefined;

    private _careHome: CareHome;
    get careHome(): CareHome {
      return this._careHome;
    }
    set careHome(value: CareHome) {
      this._careHome = value;
      this.careHomeGuid = value?.guid ?? null;
    }

    constructor() {
      super('CareHomeUser');
      ValidationRules
        .ensure((e: CareHomeUser) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.careHomeGuid).required().satisfiesRule('guid')
        .ensure((e) => e.userGuid).required().satisfiesRule('guid')
        .ensure((e) => e.isDefaultCareHome).required().satisfiesRule('bit')
        .on(this);
    }

    static map(src?: any): CareHomeUser {
      const entity = Object.assign(new CareHomeUser(), src) as CareHomeUser;
      entity.careHome = Object.assign(new CareHome(), entity.careHome);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return CareHomeUser.map(src);
    }
  }

  export class Doctor extends Entity<Doctor> {

    id: number;
    name: string = undefined;
    practice: string = undefined;
    telephoneNumber?: string = null;
    cellPhoneNumber?: string = null;
    emailAddress?: string = null;
    addressLine1?: string = null;
    addressLine2?: string = null;
    suburb?: string = null;
    city?: string = null;
    postalCode?: string = null;
    created: Date = undefined;
    lastEdited: Date = undefined;
    pharmacyGuid?: string = null;

    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }

    constructor() {
      super('Doctor');
      ValidationRules
        .ensure((e: Doctor) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.name).required().maxLength(255)
        .ensure((e) => e.practice).required().maxLength(255)
        .ensure((e) => e.telephoneNumber).maxLength(255)
        .ensure((e) => e.cellPhoneNumber).maxLength(255)
        .ensure((e) => e.emailAddress).maxLength(255)
        .ensure((e) => e.addressLine1).maxLength(255)
        .ensure((e) => e.addressLine2).maxLength(255)
        .ensure((e) => e.suburb).maxLength(255)
        .ensure((e) => e.city).maxLength(255)
        .ensure((e) => e.postalCode).maxLength(255)
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).required().satisfiesRule('date')
        .ensure((e) => e.pharmacyGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): Doctor {
      const entity = Object.assign(new Doctor(), src) as Doctor;
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return Doctor.map(src);
    }
  }

  export class EMail extends Entity<EMail> {

    id: number;
    address: string = undefined;
    subject: string = undefined;
    body: string = undefined;
    senderName: string = undefined;
    senderAddress: string = undefined;
    carbonCopy?: string = null;
    attachment?: any;
    attachmentName?: string = null;
    status: string = undefined;
    statusMessage?: string = null;
    dateTimeSend?: Date = null;
    timestamp: Date = undefined;
    rowversion: any;

    constructor() {
      super('EMail');
      ValidationRules
        .ensure((e: EMail) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.address).required().maxLength(255)
        .ensure((e) => e.subject).required().maxLength(255)
        .ensure((e) => e.body).required().maxLength(8388608)
        .ensure((e) => e.senderName).required().maxLength(255)
        .ensure((e) => e.senderAddress).required().maxLength(255)
        .ensure((e) => e.carbonCopy).maxLength(255)
        .ensure((e) => e.attachmentName).maxLength(255)
        .ensure((e) => e.status).required().maxLength(50)
        .ensure((e) => e.statusMessage).maxLength(255)
        .ensure((e) => e.dateTimeSend).satisfiesRule('date')
        .ensure((e) => e.timestamp).required().satisfiesRule('date')
        .on(this);
    }

    static map(src?: any): EMail {
      const entity = Object.assign(new EMail(), src) as EMail;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return EMail.map(src);
    }
  }

  export class Frequency extends Entity<Frequency> {

    id: number;
    entity: string = undefined;
    entityGuid: string = undefined;
    occurrence: number;
    lastUsed: Date = undefined;
    pharmacyGuid?: string = null;

    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }

    constructor() {
      super('Frequency');
      ValidationRules
        .ensure((e: Frequency) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.entity).required().maxLength(50)
        .ensure((e) => e.entityGuid).required().satisfiesRule('guid')
        .ensure((e) => e.occurrence).required().satisfiesRule('int')
        .ensure((e) => e.lastUsed).required().satisfiesRule('date')
        .ensure((e) => e.pharmacyGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): Frequency {
      const entity = Object.assign(new Frequency(), src) as Frequency;
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return Frequency.map(src);
    }
  }

  export class Gender extends Entity<Gender> {

    id: number;
    name: string = undefined;
    orderBy: number;

    constructor() {
      super('Gender');
      ValidationRules
        .ensure((e: Gender) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.name).required().maxLength(50)
        .ensure((e) => e.orderBy).required().satisfiesRule('int')
        .on(this);
    }

    static map(src?: any): Gender {
      const entity = Object.assign(new Gender(), src) as Gender;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return Gender.map(src);
    }
  }

  export class HtmlToPdf extends Entity<HtmlToPdf> {

    id: number;
    html?: string = null;
    htmlDeflated?: any;
    pdf?: any;
    status: string = undefined;
    statusMessage?: string = null;
    timestamp: Date = undefined;
    pharmacyGuid?: string = null;

    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }

    constructor() {
      super('HtmlToPdf');
      ValidationRules
        .ensure((e: HtmlToPdf) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.html).maxLength(8388608)
        .ensure((e) => e.status).required().maxLength(50)
        .ensure((e) => e.statusMessage).maxLength(255)
        .ensure((e) => e.timestamp).required().satisfiesRule('date')
        .ensure((e) => e.pharmacyGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): HtmlToPdf {
      const entity = Object.assign(new HtmlToPdf(), src) as HtmlToPdf;
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return HtmlToPdf.map(src);
    }
  }

  export class ICD10 extends Entity<ICD10> {

    id: number;
    code: string = undefined;
    name: string = undefined;
    description: string = undefined;
    created: Date = undefined;
    lastEdited: Date = undefined;

    constructor() {
      super('ICD10');
      ValidationRules
        .ensure((e: ICD10) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.code).required().maxLength(20)
        .ensure((e) => e.name).required().maxLength(255)
        .ensure((e) => e.description).required().maxLength(255)
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).required().satisfiesRule('date')
        .on(this);
    }

    static map(src?: any): ICD10 {
      const entity = Object.assign(new ICD10(), src) as ICD10;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return ICD10.map(src);
    }
  }

  export class Logger extends Entity<Logger> {

    id: number;
    msg?: string = null;
    dateTime?: Date = null;
    userGuid?: string = null;

    constructor() {
      super('Logger');
      ValidationRules
        .ensure((e: Logger) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.msg).maxLength(255)
        .ensure((e) => e.dateTime).satisfiesRule('date')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): Logger {
      const entity = Object.assign(new Logger(), src) as Logger;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return Logger.map(src);
    }
  }

  export class Medication extends Entity<Medication> {

    id: number;
    code: string = undefined;
    name: string = undefined;
    description: string = undefined;
    schedule?: string = null;
    strength?: string = null;
    form?: string = null;
    isActive: boolean = undefined;
    isDiscontinued: boolean = undefined;
    photo?: any;
    photoName?: string = null;
    photoLength?: number;
    created: Date = undefined;
    lastEdited: Date = undefined;

    constructor() {
      super('Medication');
      ValidationRules
        .ensure((e: Medication) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.code).required().maxLength(20)
        .ensure((e) => e.name).required().maxLength(255)
        .ensure((e) => e.description).required().maxLength(255)
        .ensure((e) => e.schedule).maxLength(20)
        .ensure((e) => e.strength).maxLength(50)
        .ensure((e) => e.form).maxLength(50)
        .ensure((e) => e.isActive).required().satisfiesRule('bit')
        .ensure((e) => e.isDiscontinued).required().satisfiesRule('bit')
        .ensure((e) => e.photoName).maxLength(255)
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).required().satisfiesRule('date')
        .on(this);
    }

    static map(src?: any): Medication {
      const entity = Object.assign(new Medication(), src) as Medication;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return Medication.map(src);
    }
  }

  export class MedicationPack extends Entity<MedicationPack> {

    id: number;
    instructions: string = undefined;
    created: Date = undefined;
    lastEdited?: Date = null;
    createdAt: number;
    updatedAt: number;
    patientPackGuid: string = undefined;
    patientMedicationGuid: string = undefined;
    medicationGuid: string = undefined;
    doctorGuid: string = undefined;
    iCD10Guid: string = undefined;
    patientMedicationStatusGuid: string = undefined;

    private _doctor: Doctor;
    get doctor(): Doctor {
      return this._doctor;
    }
    set doctor(value: Doctor) {
      this._doctor = value;
      this.doctorGuid = value?.guid ?? null;
    }
    private _iCD10: ICD10;
    get iCD10(): ICD10 {
      return this._iCD10;
    }
    set iCD10(value: ICD10) {
      this._iCD10 = value;
      this.iCD10Guid = value?.guid ?? null;
    }
    private _medication: Medication;
    get medication(): Medication {
      return this._medication;
    }
    set medication(value: Medication) {
      this._medication = value;
      this.medicationGuid = value?.guid ?? null;
    }
    private _patientPack: PatientPack;
    get patientPack(): PatientPack {
      return this._patientPack;
    }
    set patientPack(value: PatientPack) {
      this._patientPack = value;
      this.patientPackGuid = value?.guid ?? null;
    }
    private _patientMedication: PatientMedication;
    get patientMedication(): PatientMedication {
      return this._patientMedication;
    }
    set patientMedication(value: PatientMedication) {
      this._patientMedication = value;
      this.patientMedicationGuid = value?.guid ?? null;
    }
    private _patientMedicationStatus: PatientMedicationStatus;
    get patientMedicationStatus(): PatientMedicationStatus {
      return this._patientMedicationStatus;
    }
    set patientMedicationStatus(value: PatientMedicationStatus) {
      this._patientMedicationStatus = value;
      this.patientMedicationStatusGuid = value?.guid ?? null;
    }

    constructor() {
      super('MedicationPack');
      ValidationRules
        .ensure((e: MedicationPack) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.instructions).required().maxLength(1024)
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).satisfiesRule('date')
        .ensure((e) => e.createdAt).required().satisfiesRule('bigint')
        .ensure((e) => e.updatedAt).required().satisfiesRule('bigint')
        .ensure((e) => e.patientPackGuid).required().satisfiesRule('guid')
        .ensure((e) => e.patientMedicationGuid).required().satisfiesRule('guid')
        .ensure((e) => e.medicationGuid).required().satisfiesRule('guid')
        .ensure((e) => e.doctorGuid).required().satisfiesRule('guid')
        .ensure((e) => e.iCD10Guid).required().satisfiesRule('guid')
        .ensure((e) => e.patientMedicationStatusGuid).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): MedicationPack {
      const entity = Object.assign(new MedicationPack(), src) as MedicationPack;
      entity.doctor = Object.assign(new Doctor(), entity.doctor);
      entity.iCD10 = Object.assign(new ICD10(), entity.iCD10);
      entity.medication = Object.assign(new Medication(), entity.medication);
      entity.patientPack = Object.assign(new PatientPack(), entity.patientPack);
      entity.patientMedication = Object.assign(new PatientMedication(), entity.patientMedication);
      entity.patientMedicationStatus = Object.assign(new PatientMedicationStatus(), entity.patientMedicationStatus);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return MedicationPack.map(src);
    }
  }

  export class MedicationPackSchedule extends Entity<MedicationPackSchedule> {

    id: number;
    week: number;
    day: number;
    timeSlot: number;
    dosage: number;
    status?: string = null;
    statusReason?: string = null;
    statusAt?: number;
    createdAt: number;
    updatedAt: number;
    medicationPackGuid: string = undefined;
    userGuid?: string = null;

    private _medicationPack: MedicationPack;
    get medicationPack(): MedicationPack {
      return this._medicationPack;
    }
    set medicationPack(value: MedicationPack) {
      this._medicationPack = value;
      this.medicationPackGuid = value?.guid ?? null;
    }

    constructor() {
      super('MedicationPackSchedule');
      ValidationRules
        .ensure((e: MedicationPackSchedule) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.week).required().satisfiesRule('int')
        .ensure((e) => e.day).required().satisfiesRule('int')
        .ensure((e) => e.timeSlot).required().satisfiesRule('int')
        .ensure((e) => e.dosage).required().satisfiesRule('money')
        .ensure((e) => e.status).maxLength(50)
        .ensure((e) => e.statusReason).maxLength(50)
        .ensure((e) => e.statusAt).satisfiesRule('bigint')
        .ensure((e) => e.createdAt).required().satisfiesRule('bigint')
        .ensure((e) => e.updatedAt).required().satisfiesRule('bigint')
        .ensure((e) => e.medicationPackGuid).required().satisfiesRule('guid')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): MedicationPackSchedule {
      const entity = Object.assign(new MedicationPackSchedule(), src) as MedicationPackSchedule;
      entity.medicationPack = Object.assign(new MedicationPack(), entity.medicationPack);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return MedicationPackSchedule.map(src);
    }
  }

  export class MedicationSchedule extends Entity<MedicationSchedule> {

    id: number;
    week: number;
    day: number;
    timeSlot: number;
    dosage: number;
    created: Date = undefined;
    lastEdited?: Date = null;
    patientMedicationGuid: string = undefined;
    sysStartTime?: Date = null;
    sysEndTime?: Date = null;

    private _patientMedication: PatientMedication;
    get patientMedication(): PatientMedication {
      return this._patientMedication;
    }
    set patientMedication(value: PatientMedication) {
      this._patientMedication = value;
      this.patientMedicationGuid = value?.guid ?? null;
    }

    constructor() {
      super('MedicationSchedule');
      ValidationRules
        .ensure((e: MedicationSchedule) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.week).required().satisfiesRule('int')
        .ensure((e) => e.day).required().satisfiesRule('int')
        .ensure((e) => e.timeSlot).required().satisfiesRule('int')
        .ensure((e) => e.dosage).required().satisfiesRule('money')
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).satisfiesRule('date')
        .ensure((e) => e.patientMedicationGuid).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): MedicationSchedule {
      const entity = Object.assign(new MedicationSchedule(), src) as MedicationSchedule;
      entity.patientMedication = Object.assign(new PatientMedication(), entity.patientMedication);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return MedicationSchedule.map(src);
    }
  }

  export class Notice extends Entity<Notice> {

    id: number;
    type?: string = null;
    subject?: string = null;
    body?: string = null;
    created: Date = undefined;
    lastEdited: Date = undefined;

    constructor() {
      super('Notice');
      ValidationRules
        .ensure((e: Notice) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.type).maxLength(50)
        .ensure((e) => e.subject).maxLength(255)
        .ensure((e) => e.body).maxLength(8388608)
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).required().satisfiesRule('date')
        .on(this);
    }

    static map(src?: any): Notice {
      const entity = Object.assign(new Notice(), src) as Notice;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return Notice.map(src);
    }
  }

  export class PackingGroup extends Entity<PackingGroup> {

    id: number;
    name: string = undefined;
    description: string = undefined;
    orderBy: number;
    pharmacyGuid: string = undefined;

    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }

    constructor() {
      super('PackingGroup');
      ValidationRules
        .ensure((e: PackingGroup) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.name).required().maxLength(255)
        .ensure((e) => e.description).required().maxLength(255)
        .ensure((e) => e.orderBy).required().satisfiesRule('int')
        .ensure((e) => e.pharmacyGuid).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PackingGroup {
      const entity = Object.assign(new PackingGroup(), src) as PackingGroup;
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PackingGroup.map(src);
    }
  }

  export class Patient extends Entity<Patient> {

    id: number;
    firstName: string = undefined;
    surname: string = undefined;
    idPassportNumber?: string = null;
    cellPhoneNumber?: string = null;
    telephoneNumber?: string = null;
    emailAddress?: string = null;
    notes?: string = null;
    addressLine1?: string = null;
    addressLine2?: string = null;
    suburb?: string = null;
    city?: string = null;
    postalCode?: number;
    photoName?: string = null;
    photo?: any;
    photoLength?: number;
    pharmacyProfileNumber?: string = null;
    homeReferenceNumber?: string = null;
    contactName?: string = null;
    contactSurname?: string = null;
    careHomeName?: string = null;
    alternate?: string = null;
    medicalAid?: string = null;
    medicalAidNumber?: string = null;
    allergies?: string = null;
    medicalConditions?: string = null;
    isActive: boolean = undefined;
    contactNotes?: string = null;
    lastPrinted?: Date = null;
    medicationOrderEmailAddress?: string = null;
    created: Date = undefined;
    lastEdited: Date = undefined;
    titleGuid?: string = null;
    genderGuid?: string = null;
    pharmacyGuid?: string = null;
    careHomeGuid?: string = null;
    subGroupGuid?: string = null;
    userGuid?: string = null;
    sysStartTime: Date = undefined;
    sysEndTime: Date = undefined;

    private _careHome: CareHome;
    get careHome(): CareHome {
      return this._careHome;
    }
    set careHome(value: CareHome) {
      this._careHome = value;
      this.careHomeGuid = value?.guid ?? null;
    }
    private _gender: Gender;
    get gender(): Gender {
      return this._gender;
    }
    set gender(value: Gender) {
      this._gender = value;
      this.genderGuid = value?.guid ?? null;
    }
    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }
    private _subGroup: SubGroup;
    get subGroup(): SubGroup {
      return this._subGroup;
    }
    set subGroup(value: SubGroup) {
      this._subGroup = value;
      this.subGroupGuid = value?.guid ?? null;
    }
    private _title: Title;
    get title(): Title {
      return this._title;
    }
    set title(value: Title) {
      this._title = value;
      this.titleGuid = value?.guid ?? null;
    }

    constructor() {
      super('Patient');
      ValidationRules
        .ensure((e: Patient) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.firstName).required().maxLength(255)
        .ensure((e) => e.surname).required().maxLength(255)
        .ensure((e) => e.idPassportNumber).maxLength(255)
        .ensure((e) => e.cellPhoneNumber).maxLength(255)
        .ensure((e) => e.telephoneNumber).maxLength(255)
        .ensure((e) => e.emailAddress).maxLength(255)
        .ensure((e) => e.notes).maxLength(4096)
        .ensure((e) => e.addressLine1).maxLength(255)
        .ensure((e) => e.addressLine2).maxLength(255)
        .ensure((e) => e.suburb).maxLength(255)
        .ensure((e) => e.city).maxLength(255)
        .ensure((e) => e.postalCode).satisfiesRule('int')
        .ensure((e) => e.photoName).maxLength(255)
        .ensure((e) => e.pharmacyProfileNumber).maxLength(255)
        .ensure((e) => e.homeReferenceNumber).maxLength(255)
        .ensure((e) => e.contactName).maxLength(255)
        .ensure((e) => e.contactSurname).maxLength(255)
        .ensure((e) => e.careHomeName).maxLength(255)
        .ensure((e) => e.alternate).maxLength(255)
        .ensure((e) => e.medicalAid).maxLength(255)
        .ensure((e) => e.medicalAidNumber).maxLength(255)
        .ensure((e) => e.allergies).maxLength(4096)
        .ensure((e) => e.medicalConditions).maxLength(4096)
        .ensure((e) => e.isActive).required().satisfiesRule('bit')
        .ensure((e) => e.contactNotes).maxLength(255)
        .ensure((e) => e.lastPrinted).satisfiesRule('date')
        .ensure((e) => e.medicationOrderEmailAddress).maxLength(255)
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).required().satisfiesRule('date')
        .ensure((e) => e.titleGuid).satisfiesRule('guid')
        .ensure((e) => e.genderGuid).satisfiesRule('guid')
        .ensure((e) => e.pharmacyGuid).satisfiesRule('guid')
        .ensure((e) => e.careHomeGuid).satisfiesRule('guid')
        .ensure((e) => e.subGroupGuid).satisfiesRule('guid')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): Patient {
      const entity = Object.assign(new Patient(), src) as Patient;
      entity.careHome = Object.assign(new CareHome(), entity.careHome);
      entity.gender = Object.assign(new Gender(), entity.gender);
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);
      entity.subGroup = Object.assign(new SubGroup(), entity.subGroup);
      entity.title = Object.assign(new Title(), entity.title);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return Patient.map(src);
    }
  }

  export class PatientDocument extends Entity<PatientDocument> {

    id: number;
    mID?: string = null;
    type?: string = null;
    photo?: any;
    photoLength?: number;
    thumbnail?: any;
    isDeleted?: boolean = null;
    createdAt: number;
    updatedAt?: number;
    patientGuid: string = undefined;
    userGuid?: string = null;

    private _patient: Patient;
    get patient(): Patient {
      return this._patient;
    }
    set patient(value: Patient) {
      this._patient = value;
      this.patientGuid = value?.guid ?? null;
    }

    constructor() {
      super('PatientDocument');
      ValidationRules
        .ensure((e: PatientDocument) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.mID).maxLength(50)
        .ensure((e) => e.type).maxLength(50)
        .ensure((e) => e.isDeleted).satisfiesRule('bit')
        .ensure((e) => e.createdAt).required().satisfiesRule('bigint')
        .ensure((e) => e.updatedAt).satisfiesRule('bigint')
        .ensure((e) => e.patientGuid).required().satisfiesRule('guid')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PatientDocument {
      const entity = Object.assign(new PatientDocument(), src) as PatientDocument;
      entity.patient = Object.assign(new Patient(), entity.patient);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PatientDocument.map(src);
    }
  }

  export class PatientHistory extends Entity<PatientHistory> {

    id: number;
    firstName: string = undefined;
    surname: string = undefined;
    idPassportNumber?: string = null;
    cellPhoneNumber?: string = null;
    telephoneNumber?: string = null;
    emailAddress?: string = null;
    notes?: string = null;
    addressLine1?: string = null;
    addressLine2?: string = null;
    suburb?: string = null;
    city?: string = null;
    postalCode?: number;
    photoName?: string = null;
    photo?: any;
    photoLength?: number;
    pharmacyProfileNumber?: string = null;
    homeReferenceNumber?: string = null;
    contactName?: string = null;
    contactSurname?: string = null;
    careHomeName?: string = null;
    alternate?: string = null;
    medicalAid?: string = null;
    medicalAidNumber?: string = null;
    allergies?: string = null;
    medicalConditions?: string = null;
    isActive: boolean = undefined;
    contactNotes?: string = null;
    lastPrinted?: Date = null;
    medicationOrderEmailAddress?: string = null;
    created: Date = undefined;
    lastEdited: Date = undefined;
    titleGuid?: string = null;
    genderGuid?: string = null;
    pharmacyGuid?: string = null;
    careHomeGuid?: string = null;
    subGroupGuid?: string = null;
    userGuid?: string = null;
    sysStartTime: Date = undefined;
    sysEndTime: Date = undefined;

    constructor() {
      super('PatientHistory');
      ValidationRules
        .ensure((e: PatientHistory) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.id).required().satisfiesRule('int')
        .ensure((e) => e.firstName).required().maxLength(255)
        .ensure((e) => e.surname).required().maxLength(255)
        .ensure((e) => e.idPassportNumber).maxLength(255)
        .ensure((e) => e.cellPhoneNumber).maxLength(255)
        .ensure((e) => e.telephoneNumber).maxLength(255)
        .ensure((e) => e.emailAddress).maxLength(255)
        .ensure((e) => e.notes).maxLength(4096)
        .ensure((e) => e.addressLine1).maxLength(255)
        .ensure((e) => e.addressLine2).maxLength(255)
        .ensure((e) => e.suburb).maxLength(255)
        .ensure((e) => e.city).maxLength(255)
        .ensure((e) => e.postalCode).satisfiesRule('int')
        .ensure((e) => e.photoName).maxLength(255)
        .ensure((e) => e.photoLength).satisfiesRule('bigint')
        .ensure((e) => e.pharmacyProfileNumber).maxLength(255)
        .ensure((e) => e.homeReferenceNumber).maxLength(255)
        .ensure((e) => e.contactName).maxLength(255)
        .ensure((e) => e.contactSurname).maxLength(255)
        .ensure((e) => e.careHomeName).maxLength(255)
        .ensure((e) => e.alternate).maxLength(255)
        .ensure((e) => e.medicalAid).maxLength(255)
        .ensure((e) => e.medicalAidNumber).maxLength(255)
        .ensure((e) => e.allergies).maxLength(4096)
        .ensure((e) => e.medicalConditions).maxLength(4096)
        .ensure((e) => e.isActive).required().satisfiesRule('bit')
        .ensure((e) => e.contactNotes).maxLength(255)
        .ensure((e) => e.lastPrinted).satisfiesRule('date')
        .ensure((e) => e.medicationOrderEmailAddress).maxLength(255)
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).required().satisfiesRule('date')
        .ensure((e) => e.titleGuid).satisfiesRule('guid')
        .ensure((e) => e.genderGuid).satisfiesRule('guid')
        .ensure((e) => e.pharmacyGuid).satisfiesRule('guid')
        .ensure((e) => e.careHomeGuid).satisfiesRule('guid')
        .ensure((e) => e.subGroupGuid).satisfiesRule('guid')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PatientHistory {
      const entity = Object.assign(new PatientHistory(), src) as PatientHistory;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PatientHistory.map(src);
    }
  }

  export class PatientLastPrinted extends Entity<PatientLastPrinted> {

    id: number;
    lastPrinted?: Date = null;
    printedCount?: number;
    patientGuid: string = undefined;
    userGuid?: string = null;

    private _patient: Patient;
    get patient(): Patient {
      return this._patient;
    }
    set patient(value: Patient) {
      this._patient = value;
      this.patientGuid = value?.guid ?? null;
    }

    constructor() {
      super('PatientLastPrinted');
      ValidationRules
        .ensure((e: PatientLastPrinted) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.lastPrinted).satisfiesRule('date')
        .ensure((e) => e.printedCount).satisfiesRule('int')
        .ensure((e) => e.patientGuid).required().satisfiesRule('guid')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PatientLastPrinted {
      const entity = Object.assign(new PatientLastPrinted(), src) as PatientLastPrinted;
      entity.patient = Object.assign(new Patient(), entity.patient);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PatientLastPrinted.map(src);
    }
  }

  export class PatientMeasurement extends Entity<PatientMeasurement> {

    id: number;
    mID?: string = null;
    measurementType?: string = null;
    key?: string = null;
    value?: string = null;
    isDeleted?: boolean = null;
    capturedAt: number;
    createdAt: number;
    updatedAt?: number;
    patientGuid: string = undefined;
    userGuid?: string = null;

    private _patient: Patient;
    get patient(): Patient {
      return this._patient;
    }
    set patient(value: Patient) {
      this._patient = value;
      this.patientGuid = value?.guid ?? null;
    }

    constructor() {
      super('PatientMeasurement');
      ValidationRules
        .ensure((e: PatientMeasurement) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.mID).maxLength(50)
        .ensure((e) => e.measurementType).maxLength(50)
        .ensure((e) => e.key).maxLength(50)
        .ensure((e) => e.value).maxLength(50)
        .ensure((e) => e.isDeleted).satisfiesRule('bit')
        .ensure((e) => e.capturedAt).required().satisfiesRule('bigint')
        .ensure((e) => e.createdAt).required().satisfiesRule('bigint')
        .ensure((e) => e.updatedAt).satisfiesRule('bigint')
        .ensure((e) => e.patientGuid).required().satisfiesRule('guid')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PatientMeasurement {
      const entity = Object.assign(new PatientMeasurement(), src) as PatientMeasurement;
      entity.patient = Object.assign(new Patient(), entity.patient);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PatientMeasurement.map(src);
    }
  }

  export class PatientMedication extends Entity<PatientMedication> {

    id: number;
    scriptValidTo: Date = undefined;
    dateWritten: Date = undefined;
    pharmacyRXNumber?: string = null;
    instructions?: string = null;
    repeats: number;
    isComprehensiveSchedule: boolean = undefined;
    refillFrequency?: number;
    refillDate?: Date = null;
    created: Date = undefined;
    lastEdited?: Date = null;
    simpleSchedule?: string = null;
    lastSimpleScheduleCommand?: string = null;
    patientGuid: string = undefined;
    medicationGuid: string = undefined;
    doctorGuid: string = undefined;
    iCD10Guid: string = undefined;
    patientMedicationStatusGuid: string = undefined;
    userGuid?: string = null;
    sysStartTime: Date = undefined;
    sysEndTime: Date = undefined;
    substitutedWithGuid?: string = null;

    private _doctor: Doctor;
    get doctor(): Doctor {
      return this._doctor;
    }
    set doctor(value: Doctor) {
      this._doctor = value;
      this.doctorGuid = value?.guid ?? null;
    }
    private _iCD10: ICD10;
    get iCD10(): ICD10 {
      return this._iCD10;
    }
    set iCD10(value: ICD10) {
      this._iCD10 = value;
      this.iCD10Guid = value?.guid ?? null;
    }
    private _medication: Medication;
    get medication(): Medication {
      return this._medication;
    }
    set medication(value: Medication) {
      this._medication = value;
      this.medicationGuid = value?.guid ?? null;
    }
    private _patient: Patient;
    get patient(): Patient {
      return this._patient;
    }
    set patient(value: Patient) {
      this._patient = value;
      this.patientGuid = value?.guid ?? null;
    }
    private _patientMedicationStatus: PatientMedicationStatus;
    get patientMedicationStatus(): PatientMedicationStatus {
      return this._patientMedicationStatus;
    }
    set patientMedicationStatus(value: PatientMedicationStatus) {
      this._patientMedicationStatus = value;
      this.patientMedicationStatusGuid = value?.guid ?? null;
    }

    constructor() {
      super('PatientMedication');
      ValidationRules
        .ensure((e: PatientMedication) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.scriptValidTo).required().satisfiesRule('date')
        .ensure((e) => e.dateWritten).required().satisfiesRule('date')
        .ensure((e) => e.pharmacyRXNumber).maxLength(255)
        .ensure((e) => e.instructions).maxLength(1024)
        .ensure((e) => e.repeats).required().satisfiesRule('int')
        .ensure((e) => e.isComprehensiveSchedule).required().satisfiesRule('bit')
        .ensure((e) => e.refillFrequency).satisfiesRule('int')
        .ensure((e) => e.refillDate).satisfiesRule('date')
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).satisfiesRule('date')
        .ensure((e) => e.simpleSchedule).maxLength(1024)
        .ensure((e) => e.lastSimpleScheduleCommand).maxLength(512)
        .ensure((e) => e.patientGuid).required().satisfiesRule('guid')
        .ensure((e) => e.medicationGuid).required().satisfiesRule('guid')
        .ensure((e) => e.doctorGuid).required().satisfiesRule('guid')
        .ensure((e) => e.iCD10Guid).required().satisfiesRule('guid')
        .ensure((e) => e.patientMedicationStatusGuid).required().satisfiesRule('guid')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .ensure((e) => e.substitutedWithGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PatientMedication {
      const entity = Object.assign(new PatientMedication(), src) as PatientMedication;
      entity.doctor = Object.assign(new Doctor(), entity.doctor);
      entity.iCD10 = Object.assign(new ICD10(), entity.iCD10);
      entity.medication = Object.assign(new Medication(), entity.medication);
      entity.patient = Object.assign(new Patient(), entity.patient);
      entity.patientMedicationStatus = Object.assign(new PatientMedicationStatus(), entity.patientMedicationStatus);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PatientMedication.map(src);
    }
  }

  export class PatientMedicationHistory extends Entity<PatientMedicationHistory> {

    id: number;
    scriptValidTo: Date = undefined;
    dateWritten: Date = undefined;
    pharmacyRXNumber?: string = null;
    instructions?: string = null;
    repeats: number;
    isComprehensiveSchedule: boolean = undefined;
    refillFrequency?: number;
    refillDate?: Date = null;
    created: Date = undefined;
    lastEdited?: Date = null;
    simpleSchedule?: string = null;
    lastSimpleScheduleCommand?: string = null;
    patientGuid: string = undefined;
    medicationGuid: string = undefined;
    doctorGuid: string = undefined;
    iCD10Guid: string = undefined;
    patientMedicationStatusGuid: string = undefined;
    userGuid?: string = null;
    sysStartTime: Date = undefined;
    sysEndTime: Date = undefined;
    substitutedWithGuid?: string = null;

    constructor() {
      super('PatientMedicationHistory');
      ValidationRules
        .ensure((e: PatientMedicationHistory) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.id).required().satisfiesRule('int')
        .ensure((e) => e.scriptValidTo).required().satisfiesRule('date')
        .ensure((e) => e.dateWritten).required().satisfiesRule('date')
        .ensure((e) => e.pharmacyRXNumber).maxLength(255)
        .ensure((e) => e.instructions).maxLength(1024)
        .ensure((e) => e.repeats).required().satisfiesRule('int')
        .ensure((e) => e.isComprehensiveSchedule).required().satisfiesRule('bit')
        .ensure((e) => e.refillFrequency).satisfiesRule('int')
        .ensure((e) => e.refillDate).satisfiesRule('date')
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).satisfiesRule('date')
        .ensure((e) => e.simpleSchedule).maxLength(1024)
        .ensure((e) => e.lastSimpleScheduleCommand).maxLength(512)
        .ensure((e) => e.patientGuid).required().satisfiesRule('guid')
        .ensure((e) => e.medicationGuid).required().satisfiesRule('guid')
        .ensure((e) => e.doctorGuid).required().satisfiesRule('guid')
        .ensure((e) => e.iCD10Guid).required().satisfiesRule('guid')
        .ensure((e) => e.patientMedicationStatusGuid).required().satisfiesRule('guid')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .ensure((e) => e.substitutedWithGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PatientMedicationHistory {
      const entity = Object.assign(new PatientMedicationHistory(), src) as PatientMedicationHistory;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PatientMedicationHistory.map(src);
    }
  }

  export class PatientMedicationSimpleSchedule extends Entity<PatientMedicationSimpleSchedule> {

    id: number;
    simpleSchedule?: string = null;
    lastSimpleScheduleCommand?: string = null;
    created: Date = undefined;
    lastEdited?: Date = null;
    userGuid?: string = null;
    sysStartTime: Date = undefined;
    sysEndTime: Date = undefined;

    constructor() {
      super('PatientMedicationSimpleSchedule');
      ValidationRules
        .ensure((e: PatientMedicationSimpleSchedule) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.simpleSchedule).maxLength(1024)
        .ensure((e) => e.lastSimpleScheduleCommand).maxLength(512)
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).satisfiesRule('date')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PatientMedicationSimpleSchedule {
      const entity = Object.assign(new PatientMedicationSimpleSchedule(), src) as PatientMedicationSimpleSchedule;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PatientMedicationSimpleSchedule.map(src);
    }
  }

  export class PatientMedicationSimpleScheduleHistory extends Entity<PatientMedicationSimpleScheduleHistory> {

    id: number;
    simpleSchedule?: string = null;
    lastSimpleScheduleCommand?: string = null;
    created: Date = undefined;
    lastEdited?: Date = null;
    userGuid?: string = null;
    sysStartTime: Date = undefined;
    sysEndTime: Date = undefined;

    constructor() {
      super('PatientMedicationSimpleScheduleHistory');
      ValidationRules
        .ensure((e: PatientMedicationSimpleScheduleHistory) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.id).required().satisfiesRule('int')
        .ensure((e) => e.simpleSchedule).maxLength(1024)
        .ensure((e) => e.lastSimpleScheduleCommand).maxLength(512)
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).satisfiesRule('date')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PatientMedicationSimpleScheduleHistory {
      const entity = Object.assign(new PatientMedicationSimpleScheduleHistory(), src) as PatientMedicationSimpleScheduleHistory;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PatientMedicationSimpleScheduleHistory.map(src);
    }
  }

  export class PatientMedicationStatus extends Entity<PatientMedicationStatus> {

    id: number;
    name: string = undefined;
    description: string = undefined;
    orderBy: number;

    constructor() {
      super('PatientMedicationStatus');
      ValidationRules
        .ensure((e: PatientMedicationStatus) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.name).required().maxLength(50)
        .ensure((e) => e.description).required().maxLength(50)
        .ensure((e) => e.orderBy).required().satisfiesRule('int')
        .on(this);
    }

    static map(src?: any): PatientMedicationStatus {
      const entity = Object.assign(new PatientMedicationStatus(), src) as PatientMedicationStatus;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PatientMedicationStatus.map(src);
    }
  }

  export class PatientPack extends Entity<PatientPack> {

    id: number;
    barcode: string = undefined;
    weekNo: number;
    startDate: Date = undefined;
    endDate: Date = undefined;
    created: Date = undefined;
    lastEdited?: Date = null;
    createdAt: number;
    updatedAt: number;
    printWeekLogGuid: string = undefined;
    patientGuid: string = undefined;
    treatmentPlanGuid: string = undefined;
    userGuid?: string = null;

    private _patient: Patient;
    get patient(): Patient {
      return this._patient;
    }
    set patient(value: Patient) {
      this._patient = value;
      this.patientGuid = value?.guid ?? null;
    }
    private _printWeekLog: PrintWeekLog;
    get printWeekLog(): PrintWeekLog {
      return this._printWeekLog;
    }
    set printWeekLog(value: PrintWeekLog) {
      this._printWeekLog = value;
      this.printWeekLogGuid = value?.guid ?? null;
    }

    constructor() {
      super('PatientPack');
      ValidationRules
        .ensure((e: PatientPack) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.barcode).required().maxLength(50)
        .ensure((e) => e.weekNo).required().satisfiesRule('int')
        .ensure((e) => e.startDate).required().satisfiesRule('date')
        .ensure((e) => e.endDate).required().satisfiesRule('date')
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).satisfiesRule('date')
        .ensure((e) => e.createdAt).required().satisfiesRule('bigint')
        .ensure((e) => e.updatedAt).required().satisfiesRule('bigint')
        .ensure((e) => e.printWeekLogGuid).required().satisfiesRule('guid')
        .ensure((e) => e.patientGuid).required().satisfiesRule('guid')
        .ensure((e) => e.treatmentPlanGuid).required().satisfiesRule('guid')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PatientPack {
      const entity = Object.assign(new PatientPack(), src) as PatientPack;
      entity.patient = Object.assign(new Patient(), entity.patient);
      entity.printWeekLog = Object.assign(new PrintWeekLog(), entity.printWeekLog);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PatientPack.map(src);
    }
  }

  export class Pharmacy extends Entity<Pharmacy> {

    id: number;
    name: string = undefined;
    bHFNumber?: number;
    contactPerson?: string = null;
    telephoneNumber?: string = null;
    cellPhoneNumber?: string = null;
    emailAddress?: string = null;
    addressLine1?: string = null;
    addressLine2?: string = null;
    suburb?: string = null;
    city?: string = null;
    postalCode?: number;
    logo?: any;
    logoName?: string = null;
    isActive: boolean = undefined;
    faxNumber?: string = null;
    accountType: number;
    account?: string = null;
    billedAnnually: boolean = undefined;
    vATRegistrationNumber?: string = null;
    isPrintingDisabled: boolean = undefined;
    sendWeeklyToBePackedReport: boolean = undefined;
    aPIUserName?: string = null;
    aPIPassword?: string = null;


    constructor() {
      super('Pharmacy');
      ValidationRules
        .ensure((e: Pharmacy) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.name).required().maxLength(255)
        .ensure((e) => e.bHFNumber).satisfiesRule('int')
        .ensure((e) => e.contactPerson).maxLength(255)
        .ensure((e) => e.telephoneNumber).maxLength(255)
        .ensure((e) => e.cellPhoneNumber).maxLength(255)
        .ensure((e) => e.emailAddress).maxLength(255)
        .ensure((e) => e.addressLine1).maxLength(255)
        .ensure((e) => e.addressLine2).maxLength(255)
        .ensure((e) => e.suburb).maxLength(255)
        .ensure((e) => e.city).maxLength(255)
        .ensure((e) => e.postalCode).satisfiesRule('int')
        .ensure((e) => e.logoName).maxLength(255)
        .ensure((e) => e.isActive).required().satisfiesRule('bit')
        .ensure((e) => e.faxNumber).maxLength(255)
        .ensure((e) => e.accountType).required().satisfiesRule('int')
        .ensure((e) => e.billedAnnually).required().satisfiesRule('bit')
        .ensure((e) => e.vATRegistrationNumber).maxLength(255)
        .ensure((e) => e.isPrintingDisabled).required().satisfiesRule('bit')
        .ensure((e) => e.sendWeeklyToBePackedReport).required().satisfiesRule('bit')
        .ensure((e) => e.aPIUserName).maxLength(255)
        .ensure((e) => e.aPIPassword).maxLength(255)
        .on(this);
    }

    static map(src?: any): Pharmacy {
      const entity = Object.assign(new Pharmacy(), src) as Pharmacy;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return Pharmacy.map(src);
    }
  }

  export class PharmacyPatient extends Entity<PharmacyPatient> {

    id: number;
    isActive: boolean = undefined;
    patientGuid: string = undefined;
    pharmacyGuid: string = undefined;
    careHomeGuid?: string = null;
    subGroupGuid?: string = null;
    created: Date = undefined;
    lastEdited?: Date = null;
    userGuid?: string = null;

    private _careHome: CareHome;
    get careHome(): CareHome {
      return this._careHome;
    }
    set careHome(value: CareHome) {
      this._careHome = value;
      this.careHomeGuid = value?.guid ?? null;
    }
    private _patient: Patient;
    get patient(): Patient {
      return this._patient;
    }
    set patient(value: Patient) {
      this._patient = value;
      this.patientGuid = value?.guid ?? null;
    }
    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }
    private _subGroup: SubGroup;
    get subGroup(): SubGroup {
      return this._subGroup;
    }
    set subGroup(value: SubGroup) {
      this._subGroup = value;
      this.subGroupGuid = value?.guid ?? null;
    }

    constructor() {
      super('PharmacyPatient');
      ValidationRules
        .ensure((e: PharmacyPatient) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.isActive).required().satisfiesRule('bit')
        .ensure((e) => e.patientGuid).required().satisfiesRule('guid')
        .ensure((e) => e.pharmacyGuid).required().satisfiesRule('guid')
        .ensure((e) => e.careHomeGuid).satisfiesRule('guid')
        .ensure((e) => e.subGroupGuid).satisfiesRule('guid')
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.lastEdited).satisfiesRule('date')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PharmacyPatient {
      const entity = Object.assign(new PharmacyPatient(), src) as PharmacyPatient;
      entity.careHome = Object.assign(new CareHome(), entity.careHome);
      entity.patient = Object.assign(new Patient(), entity.patient);
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);
      entity.subGroup = Object.assign(new SubGroup(), entity.subGroup);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PharmacyPatient.map(src);
    }
  }

  export class PharmacyUser extends Entity<PharmacyUser> {

    id: number;
    pharmacyGuid: string = undefined;
    userGuid: string = undefined;
    isDefaultPharmacy: boolean = undefined;

    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }

    constructor() {
      super('PharmacyUser');
      ValidationRules
        .ensure((e: PharmacyUser) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.pharmacyGuid).required().satisfiesRule('guid')
        .ensure((e) => e.userGuid).required().satisfiesRule('guid')
        .ensure((e) => e.isDefaultPharmacy).required().satisfiesRule('bit')
        .on(this);
    }

    static map(src?: any): PharmacyUser {
      const entity = Object.assign(new PharmacyUser(), src) as PharmacyUser;
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PharmacyUser.map(src);
    }
  }

  export class PrintMetric extends Entity<PrintMetric> {

    id: number;
    printAt: Date = undefined;
    printType: string = undefined;
    counter: number;
    pharmacyGuid: string = undefined;

    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }

    constructor() {
      super('PrintMetric');
      ValidationRules
        .ensure((e: PrintMetric) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.printAt).required().satisfiesRule('date')
        .ensure((e) => e.printType).required().maxLength(50)
        .ensure((e) => e.counter).required().satisfiesRule('int')
        .ensure((e) => e.pharmacyGuid).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PrintMetric {
      const entity = Object.assign(new PrintMetric(), src) as PrintMetric;
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PrintMetric.map(src);
    }
  }

  export class PrintWeekLog extends Entity<PrintWeekLog> {

    id: number;
    printType: string = undefined;
    patientList: string = undefined;
    date: Date = undefined;
    week1: boolean = undefined;
    week2: boolean = undefined;
    week3: boolean = undefined;
    week4: boolean = undefined;
    storedLocation?: string = null;
    createdAt: Date = undefined;
    pharmacyGuid: string = undefined;
    userGuid?: string = null;

    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }

    constructor() {
      super('PrintWeekLog');
      ValidationRules
        .ensure((e: PrintWeekLog) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.printType).required().maxLength(50)
        .ensure((e) => e.patientList).required().maxLength(8000)
        .ensure((e) => e.date).required().satisfiesRule('date')
        .ensure((e) => e.week1).required().satisfiesRule('bit')
        .ensure((e) => e.week2).required().satisfiesRule('bit')
        .ensure((e) => e.week3).required().satisfiesRule('bit')
        .ensure((e) => e.week4).required().satisfiesRule('bit')
        .ensure((e) => e.storedLocation).maxLength(512)
        .ensure((e) => e.createdAt).required().satisfiesRule('date')
        .ensure((e) => e.pharmacyGuid).required().satisfiesRule('guid')
        .ensure((e) => e.userGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): PrintWeekLog {
      const entity = Object.assign(new PrintWeekLog(), src) as PrintWeekLog;
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return PrintWeekLog.map(src);
    }
  }

  export class SessionConnection extends Entity<SessionConnection> {

    id: number;
    connectionId: string = undefined;
    connectedAt: Date = undefined;
    disconnectedAt?: Date = null;
    userSessionGuid: string = undefined;

    private _userSession: UserSession;
    get userSession(): UserSession {
      return this._userSession;
    }
    set userSession(value: UserSession) {
      this._userSession = value;
      this.userSessionGuid = value?.guid ?? null;
    }

    constructor() {
      super('SessionConnection');
      ValidationRules
        .ensure((e: SessionConnection) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.connectionId).required().maxLength(50)
        .ensure((e) => e.connectedAt).required().satisfiesRule('date')
        .ensure((e) => e.disconnectedAt).satisfiesRule('date')
        .ensure((e) => e.userSessionGuid).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): SessionConnection {
      const entity = Object.assign(new SessionConnection(), src) as SessionConnection;
      entity.userSession = Object.assign(new UserSession(), entity.userSession);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return SessionConnection.map(src);
    }
  }

  export class SessionSnaphot extends Entity<SessionSnaphot> {

    id: number;
    snapshot?: any;
    created: Date = undefined;
    userSessionGuid: string = undefined;

    private _userSession: UserSession;
    get userSession(): UserSession {
      return this._userSession;
    }
    set userSession(value: UserSession) {
      this._userSession = value;
      this.userSessionGuid = value?.guid ?? null;
    }

    constructor() {
      super('SessionSnaphot');
      ValidationRules
        .ensure((e: SessionSnaphot) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.created).required().satisfiesRule('date')
        .ensure((e) => e.userSessionGuid).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): SessionSnaphot {
      const entity = Object.assign(new SessionSnaphot(), src) as SessionSnaphot;
      entity.userSession = Object.assign(new UserSession(), entity.userSession);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return SessionSnaphot.map(src);
    }
  }

  export class SubGroup extends Entity<SubGroup> {

    id: number;
    name: string = undefined;
    isActive: boolean = undefined;
    orderBy: number;
    pharmacyGuid: string = undefined;
    careHomeGuid: string = undefined;
    packingGroupGuid: string = undefined;

    private _careHome: CareHome;
    get careHome(): CareHome {
      return this._careHome;
    }
    set careHome(value: CareHome) {
      this._careHome = value;
      this.careHomeGuid = value?.guid ?? null;
    }
    private _packingGroup: PackingGroup;
    get packingGroup(): PackingGroup {
      return this._packingGroup;
    }
    set packingGroup(value: PackingGroup) {
      this._packingGroup = value;
      this.packingGroupGuid = value?.guid ?? null;
    }
    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }

    constructor() {
      super('SubGroup');
      ValidationRules
        .ensure((e: SubGroup) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.name).required().maxLength(255)
        .ensure((e) => e.isActive).required().satisfiesRule('bit')
        .ensure((e) => e.orderBy).required().satisfiesRule('int')
        .ensure((e) => e.pharmacyGuid).required().satisfiesRule('guid')
        .ensure((e) => e.careHomeGuid).required().satisfiesRule('guid')
        .ensure((e) => e.packingGroupGuid).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): SubGroup {
      const entity = Object.assign(new SubGroup(), src) as SubGroup;
      entity.careHome = Object.assign(new CareHome(), entity.careHome);
      entity.packingGroup = Object.assign(new PackingGroup(), entity.packingGroup);
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return SubGroup.map(src);
    }
  }

  export class Title extends Entity<Title> {

    id: number;
    name: string = undefined;
    orderBy: number;

    constructor() {
      super('Title');
      ValidationRules
        .ensure((e: Title) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.name).required().maxLength(50)
        .ensure((e) => e.orderBy).required().satisfiesRule('int')
        .on(this);
    }

    static map(src?: any): Title {
      const entity = Object.assign(new Title(), src) as Title;

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return Title.map(src);
    }
  }

  export class TreatmentPlan extends Entity<TreatmentPlan> {

    id: number;
    pdfWeek1?: any;
    pdfWeek2?: any;
    pdfWeek3?: any;
    pdfWeek4?: any;
    status: number;
    validAt?: Date = null;
    editedAt?: Date = null;
    patientGuid: string = undefined;

    private _patient: Patient;
    get patient(): Patient {
      return this._patient;
    }
    set patient(value: Patient) {
      this._patient = value;
      this.patientGuid = value?.guid ?? null;
    }

    constructor() {
      super('TreatmentPlan');
      ValidationRules
        .ensure((e: TreatmentPlan) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.status).required().satisfiesRule('int')
        .ensure((e) => e.validAt).satisfiesRule('date')
        .ensure((e) => e.editedAt).satisfiesRule('date')
        .ensure((e) => e.patientGuid).required().satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): TreatmentPlan {
      const entity = Object.assign(new TreatmentPlan(), src) as TreatmentPlan;
      entity.patient = Object.assign(new Patient(), entity.patient);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return TreatmentPlan.map(src);
    }
  }

  export class UserSession extends Entity<UserSession> {

    id: number;
    app?: string = null;
    remoteIpAddress?: string = null;
    fingerprint?: string = null;
    browserId?: string = null;
    userAgent?: string = null;
    lastAccessTime?: Date = null;
    lastAccessTimeRotated?: Date = null;
    revoked?: number;
    createdAt: Date = undefined;
    identityId: string = undefined;
    pharmacyGuid?: string = null;

    private _pharmacy: Pharmacy;
    get pharmacy(): Pharmacy {
      return this._pharmacy;
    }
    set pharmacy(value: Pharmacy) {
      this._pharmacy = value;
      this.pharmacyGuid = value?.guid ?? null;
    }

    constructor() {
      super('UserSession');
      ValidationRules
        .ensure((e: UserSession) => e.guid).satisfiesRule('guid')
        .ensure((e) => e.app).maxLength(50)
        .ensure((e) => e.remoteIpAddress).maxLength(20)
        .ensure((e) => e.fingerprint).maxLength(50)
        .ensure((e) => e.browserId).maxLength(50)
        .ensure((e) => e.userAgent).maxLength(255)
        .ensure((e) => e.lastAccessTime).satisfiesRule('date')
        .ensure((e) => e.lastAccessTimeRotated).satisfiesRule('date')
        .ensure((e) => e.revoked).satisfiesRule('int')
        .ensure((e) => e.createdAt).required().satisfiesRule('date')
        .ensure((e) => e.identityId).required().satisfiesRule('guid')
        .ensure((e) => e.pharmacyGuid).satisfiesRule('guid')
        .on(this);
    }

    static map(src?: any): UserSession {
      const entity = Object.assign(new UserSession(), src) as UserSession;
      entity.pharmacy = Object.assign(new Pharmacy(), entity.pharmacy);

      entity.original = { ...entity };
      return entity;
    }

    map(src: any): IEntity {
      return UserSession.map(src);
    }
  }
}
