import { ValidationRules } from 'aurelia-validation';
import { isBigInteger, isGuid, isInteger } from '@dts/scriptlib';

ValidationRules.customRule(
  'guid',
  (value, obj) => value === null || value === undefined || value === '' || isGuid(value),
  // tslint:disable-next-line:no-invalid-template-strings
  '${$displayName} must be a valid guid.',
);

ValidationRules.customRule(
  'date',
  (value, obj) => value === null || value === undefined || value === '' || !isNaN(Date.parse(value)),
  // tslint:disable-next-line:no-invalid-template-strings
  '${$displayName} must be a valid date.',
);

ValidationRules.customRule(
  'int',
  (value, obj) => value === null || value === undefined || isInteger(value),
  // tslint:disable-next-line:no-invalid-template-strings
  '${$displayName} must be an integer between -2,147,483,647 and 2,147,483,647',
);

ValidationRules.customRule(
  'bigint',
  (value, obj) => value === null || value === undefined || isBigInteger(value),
  // tslint:disable-next-line:no-invalid-template-strings
  '${$displayName} must be an integer between -9,223,372,036,854,775,808 and 9,223,372,036,854,775,807',
);

ValidationRules.customRule(
  'smallmoney',
  (value, obj) => value === null || value === undefined || (value >= -214748.3648 && value <= 214748.3647),
  // tslint:disable-next-line:no-invalid-template-strings
  '${$displayName} must be an smallmoney between -214,748.3647 and 214,748.3647',
);

ValidationRules.customRule(
  'money',
  (value, obj) => value === null || value === undefined || (value >= -922337203685477.5808 && value <= 922337203685477.5808),
  // tslint:disable-next-line:no-invalid-template-strings
  '${$displayName} must be an money between -922,337,203,685,477.5808 and 922,337,203,685,477.5808',
);

ValidationRules.customRule(
  'bit',
  (value, obj) => value === null || value === undefined || (value === false || value === true),
  // tslint:disable-next-line:no-invalid-template-strings
  '${$displayName} must be a boolean value, either true or false',
);

ValidationRules.customRule(
  'matchesProperty',
  (value, obj, otherPropertyName) =>
      value === null
      || value === undefined
      || value === ''
      || obj[otherPropertyName] === null
      || obj[otherPropertyName] === undefined
      || obj[otherPropertyName] === ''
      || value === obj[otherPropertyName],
  'The ${$getDisplayName($config.otherPropertyName)} and ${$displayName} do not match.', otherPropertyName => ({ otherPropertyName })
);
