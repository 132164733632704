import { bindable, computedFrom } from 'aurelia-framework';
import { IApiResult } from './repository';

export class ResultPager {

  @computedFrom('from', 'to')
  get pagination() {
    if (this.from > 0 && this.to > 0) {
      return `${this.from} to ${this.to}`;
    }

    return '-';
  }

  @bindable()
  public results: any[] = [];

  public loader = '';

  public from = 0;
  public to = 0;
  public itemsPerPage = 50;
  public pageSizes = [
    10,
    20,
    50,
    100,
    500,
    1000
  ];

  public pageCount: number = 10;
  public pageNumber = 1;
  public totalItems = 0;

  constructor(private readonly filterFunc: (page: number, size: number) => Promise<IApiResult>, private readonly reducer?: () => Promise<void>) {
  }

  public gotoFirst(): Promise<IApiResult> {
    this.pageNumber = 1;
    return this.filter(this.pageNumber, this.itemsPerPage);
  }

  public gotoPrevious(): Promise<IApiResult> {
    this.pageNumber = this.pageNumber > 1 ? --this.pageNumber : 1;
    return this.filter(this.pageNumber, this.itemsPerPage);
  }

  public gotoNext(): Promise<IApiResult> {
    if (this.results && this.results.length >= this.itemsPerPage && this.results.length > 0) {
      this.pageNumber++;
    }
    return this.filter(this.pageNumber, this.itemsPerPage);
  }

  public gotoLast(): Promise<IApiResult> {
    this.pageNumber = this.pageCount;
    return this.filter(this.pageNumber, this.itemsPerPage);
  }

  public gotoPage(page: number): Promise<IApiResult> {
    if (page <= 0) { page = 1; }
    if (page > this.pageCount) { page = this.pageCount; }

    this.pageNumber = page;
    return this.filter(this.pageNumber, this.itemsPerPage);
  }

  private async filter(page: number, size: number): Promise<IApiResult> {
    this.loader = 'active';

    try {
      const result = await this.filterFunc(page, size);

      this.results = result ? result.results : [];
      this.totalItems = result ? result.count : -1;
      this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage);

      this.from = (page * size) - size + 1;
      this.to = this.results.length >= size ? (page * size) : this.from + this.results.length - 1;

      if (this.reducer) {
        await this.reducer();
      }

      return result;

    } finally {
      this.loader = '';
    }
  }
}
