import { bindable } from 'aurelia-framework';
import { ResultPager } from 'plugins/data-models';

export class ResultPagerPagination {
    @bindable() public pager: ResultPager;

    public textOf = 'of';
    public textItemsPerPage = 'items per page';

    public get pageSizes(): number[] {
        return this.pager.pageSizes;
    }

    public changeToFirstPage() {
        return this.pager.gotoFirst();
    }

    public changeToLastPage() {
        return this.pager.gotoLast();
    }

    public changeToNextPage() {
        return this.pager.gotoNext();
    }

    public changeToPreviousPage() {
        return this.pager.gotoPrevious();
    }

    public changeToCurrentPage(event: any) {
        let pageNumber = 1;
        if (event && event.target && event.target.value) {
            pageNumber = +(event.target.value);
        }
        return this.pager.gotoPage(pageNumber);
    }

    public changeItemPerPage(event: any) {
        let itemsPerPage = 1;
        if (event && event.target && event.target.value) {
            itemsPerPage = +(event.target.value);
        }
        this.pager.itemsPerPage = itemsPerPage;
        return this.pager.gotoFirst();
    }
}
